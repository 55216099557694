import { isEqual } from "radash"

// helpers for managing objects

// return deep clone of an object
export const clone = function <O>(obj: O): O {
  // if we need something more complex, look at klona
  return JSON.parse(JSON.stringify(obj))
}

// in a deep comparison of two objects, are all fields the same?
export const deepEqual = function (obj1: object, obj2: object): boolean {
  return isEqual(obj1, obj2)
}

// typeguard to verify if <key> is in keys of object <obj>
export const isKeyOf = function <T extends object>(
  obj: T,
  key: any // eslint-disable-line @typescript-eslint/no-explicit-any
): key is keyof T {
  return key in obj
}

// return shallow copy of object with <key> removed
export const omit = function <O extends object, A extends keyof O>(
  value: O,
  keys: A | A[]
): Omit<O, A> {
  if (!Array.isArray(keys)) keys = Array(keys)
  const remaining = { ...value }
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  keys.forEach((key) => delete remaining[key])
  return remaining
}

// return copy of object with only certain keys included
export const pick = function <O, K extends keyof O>(
  object: O,
  keys: K[] | K
): Pick<O, K> {
  if (!Array.isArray(keys)) {
    keys = Array(keys)
  }
  return Object.assign(
    {},
    ...keys.map((key) => {
      if (object && Object.prototype.hasOwnProperty.call(object, key)) {
        return { [key]: object[key] }
      } else {
        return undefined
      }
    })
  )
}
